.wrapper {
  display: flex;
  flex-direction: column;
}

.sidebar {
  display: none;
  margin-bottom: 1.5rem;
  min-width: 290px;
}

.sidebar--visible {
  display: block;
}

.content {
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .wrapper {
    flex-direction: row;
  }

  .sidebar {
    display: block;
    flex-basis: 25%;
    padding-right: 1.5rem;
    margin-bottom: 0;
  }

  .content {
    flex-basis: 75%;
  }
}
