.container {
  max-width: 35rem;
  margin: 0 auto;
}

.header {
  text-align: center;
  margin-bottom: 3rem;
}

.form-field {
  margin-bottom: 1.5rem;
}

.form-action {
  display: flex;
  flex-direction: column;
}

.reset-password {
  text-align: right;
  margin-top: 4px;
}

.reset-password-link {
  color: var(--content-primary);
}
