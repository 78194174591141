.card {
  background: var(--bg-primary);
  border-radius: 0.5rem;
  box-shadow: 0 0.375rem 1.5rem 0 rgb(140 152 164 / 13%);
}

.card-header {
  padding: 2rem;
}

.card-header-title {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--content-primary);
}

.card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2rem;
}
