.container {
  max-width: 35rem;
  margin: 0 auto;
}

.header {
  text-align: center;
}

.form {
  margin-top: 2rem;
}

.form-field {
  margin-bottom: 1.5rem;
}

.form-action {
  display: flex;
  flex-direction: column;
}
