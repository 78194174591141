.input {
  appearance: none;
  display: block;
  padding: 0.75rem 1rem;
  background-color: #fff;
  color: var(--content-primary);
  background-clip: padding-box;
  border: 0.0625rem solid rgba(33, 50, 91, 0.1);
  border-radius: 0.3125rem;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input--empty {
  color: var(--content-secondary);
}

.input:focus {
  border-color: rgba(140, 152, 164, 0.25);
  outline: 0;
  box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
