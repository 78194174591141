@keyframes slideIn {
  from {
    right: -60%;
  }

  to {
    right: 0;
  }
}

.details-overlay {
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.details {
  animation-duration: 500ms;
  animation-name: slideIn;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

@media (min-width: 768px) {
  .details {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .details {
    width: 60%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.content-loading,
.content-error {
  text-align: center;
}

.content--hidden {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-cta {
  appearance: none;
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}
