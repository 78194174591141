.section-title {
  color: var(--content-primary);
  display: block;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.03125rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.section-items {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  margin-bottom: 1.5rem;
  padding: 0;
}

.section-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  border-left: 0.1875rem solid transparent;
  color: var(--content-primary);
  font-size: 0.9375rem;
  margin-left: -2rem;
  padding: 0.5rem;
  padding-left: 1.8125rem;
  transition: color 0.15s ease-in-out;
}

.section-item:hover {
  color: var(--action-primary);
}

.section-item--active {
  border-color: var(--border-accent-primary);
  color: var(--action-primary);
  font-weight: 600;
}

.section-item-icon {
  display: flex;
  margin-right: 1rem;
}
