/**
 * Old layout styles
 */
#order-details .in-body {
  font-family: serif;
}
#order-details .in-box-label {
  font-family: 'Arial', sans-serif;
  font-style: italic;
  font-size: 72%;
  margin-left: 1pt;
  margin-right: 1pt;
  margin-top: 1pt;
}
#order-details .box-text {
  font-family: 'Arial', sans-serif;
  font-size: 70%;
  line-height: 15pt;
  margin-left: 1pt;
  margin-right: 1pt;
}
#order-details .title-text {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 90%;
  margin-left: 10pt;
}
#order-details .subtitle-text {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  font-size: 80%;
  margin-left: 1pt;
  margin-right: 1pt;
}
#order-details .in-table {
  border-collapse: collapse;
  border-color: #000000;
}
#order-details .in-form-column {
  width: 8.33%;
}
#order-details .in-counter-cell {
  border: none;
}
#order-details .in-cell-data {
  margin-top: 0pt;
  margin-bottom: 0pt;
}
#order-details .in-cell-data-background {
}
#order-details .in-cell-data-center {
  text-align: center;
}
#order-details .in-cell-base {
  border: solid;
  border-width: 1pt;
}
#order-details .in-cell-end-override {
  border-right-style: none;
}
#order-details .in-cell-after-override {
  border-bottom-style: none;
}
#order-details .in-cell-line-background {
  background-color: #b8b8b8;
  font-weight: bold;
}
#order-details .in-cell-filler-background {
  background-color: #e8e8e8;
}
#order-details .law-warning {
  font-style: italic;
  font-size: 70%;
}
#order-details .border-left {
  border-left: 1px solid;
}
#order-details .no-bottom-border {
  border-bottom: none;
}
#order-details .allowance-charge {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
#order-details .allowance-charge LI {
}
#order-details H1 {
  font-size: 120%;
  font-family: 'Arial', sans-serif;
}
#order-details H2 {
  font-family: 'Arial', sans-serif;
  font-size: 100%;
}
#order-details H3 {
  font-size: 90%;
}
#order-details .padded {
  padding-top: 20px;
  padding-bottom: 10px;
  display: block;
}
#order-details .riepilogo-fattura {
  width: 100%;
  border: 1px solid black;
  padding: 0;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
#order-details .dati-fattura {
  width: 100%;
  height: 150px;
  border: none;
  padding: 0;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
#order-details .dati-documento {
  width: 100%;
  height: 50px;
  border: none;
  padding: 0;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
#order-details .no-border {
  border: 0 !important;
}
#order-details .riepilogo-fattura TD {
  border: 1px solid black;
  padding: 0;
  margin: 0;
  vertical-align: top;
}
#order-details .party-row {
  height: 150px;
}
#order-details .h50 {
  height: 50px;
}
#order-details .h80 {
  height: 80px;
}
#order-details .h100px {
  height: 100px;
}
#order-details .border-bottom {
  border-bottom: 1px solid black !important;
}
#order-details .border-right {
  border-right: 1px solid black !important;
}
#order-details .border-left {
  border-left: 1px solid black !important;
}
#order-details .party-column {
  width: 50%;
}
#order-details .riepilogo-PDC {
  width: 100%;
  border: 1px solid black;
  padding: 0;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
#order-details .riepilogo-PDC TH {
  font-family: 'Arial', sans-serif;
  font-size: 70%;
  font-style: italic;
  border-right: 1px solid black;
  height: 30px;
}
#order-details .w-50 {
  width: 50%;
}
#order-details .w-66 {
  width: 67%;
}
#order-details .w-90 {
  width: 90%;
}
#order-details .w-33 {
  width: 33%;
}
#order-details .w-40 {
  width: 40%;
}
#order-details .w-30 {
  width: 30%;
}
#order-details .w-25 {
  width: 25%;
}
#order-details .w-15 {
  width: 15%;
}
#order-details .w-10 {
  width: 10%;
}
#order-details .w-5 {
  width: 5%;
}
#order-details .w-100 {
  width: 100%;
}
#order-details .righe-fattura {
  width: 100%;
  border: 1px solid black;
  padding: 0;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
#order-details .righe-fattura TH {
  font-family: 'Arial', sans-serif;
  font-size: 70%;
  font-style: italic;
  border-right: 1px solid black;
  height: 30px;
}
#order-details .empty-cell-filler {
  height: 1.5em;
}
#order-details .page-break {
  page-break-inside: avoid;
}
#order-details table {
  page-break-inside: auto;
}
#order-details tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
#order-details thead {
  display: table-header-group;
}
#order-details tfoot {
  display: table-footer-group;
}
#order-details .block {
  display: block;
}
#order-details .h100 {
  height: 100%;
}
#order-details .attachment-list {
  list-style-type: none;
}
#order-details .floatright {
  float: right;
}
#order-details .float-right {
  float: right;
  margin-top: 20px;
  clear: both;
}
#order-details .float-right-last {
  float: right;
  margin-bottom: 30px;
  display: block;
  clear: both;
}
#order-details .nowraptext {
  white-space: nowrap;
  word-break: break-all;
}
#order-details .bold {
  font-weight: bold;
}
#order-details .margin-right {
  margin-right: 20px;
}
#order-details .header-linea {
  padding-top: 5px;
  padding-bottom: 3px;
  margin: 0;
}
#order-details .no-margin {
  margin: 0;
  padding: 0;
}
#order-details .font-size-80 {
  font-size: 80%;
}

/**
 * New layout styles.
 */
#order-details .header {
  margin-bottom: 16px;
}

#order-details .page-break {
  page-break-inside: avoid;
}

#order-details .section {
  break-inside: avoid;
  padding: 16px 0;
}

#order-details .section__title {
  font-size: 28px;
  margin-bottom: 24px;
}

#order-details .box {
  border: 1px solid #000;
  font-size: 14px;
  padding: 8px;
  vertical-align: top;
}

#order-details .box--group {
  padding: 0;
}

#order-details .box .box {
  border: 0;
  border-right: 1px solid #1e2022;
  border-bottom: 1px solid #1e2022;
}

#order-details .box--head {
  background: #f7faff;
  font-size: 14px;
  font-weight: 600;
}

#order-details .box__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

#order-details .box__text {
  font-size: 13px;
}

#order-details .box__text:not(:last-of-type) {
  margin-bottom: 6px;
}

#order-details .footer {
  padding: 16px 0;
  text-align: center;
}

#order-details .footer__info {
  margin-top: 8px;
}

/* Utils */
#order-details .border-right-0 {
  border-right: 0 !important;
}

#order-details .border-bottom-0 {
  border-bottom: 0 !important;
}

#order-details .list-style-none {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#order-details .w-50 {
  width: 50%;
}

#order-details .w-66 {
  width: 67%;
}

#order-details .w-33 {
  width: 33%;
}

#order-details .w-100 {
  width: 100%;
}

#order-details .text-center {
  text-align: center;
}

#order-details .text-right {
  text-align: right;
}

#order-details .floatright {
  float: right;
}
