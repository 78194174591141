.form-control-group {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.form-control {
  width: 100%;
}

.form-global-error {
  color: var(--error);
  margin-bottom: 1rem;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .form-control {
    width: calc(calc(100% - 1.5rem) / 2);
  }

  .form-actions {
    flex-direction: row;
  }
}
