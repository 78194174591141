.header {
  background: var(--bg-primary);
  margin-top: 0.5rem;
}

.header-wrapper {
  max-width: var(--layout-max-width);
  margin: 0 auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.nav {
  border-top: 8px solid var(--border-accent-primary);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

.logo {
  margin-right: auto;
}

.logout {
  margin-left: 1rem;
}

.navbar {
  background: var(--bg-tertiary);
  padding-top: 3rem;
  padding-bottom: 6rem;
}

.menu-trigger {
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0.25rem;
  margin-left: 0.5rem;
}

@media (min-width: 992px) {
  .menu-trigger {
    display: none;
  }
}
