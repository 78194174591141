.form-control-group {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.form-control {
  width: 100%;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
  padding-bottom: 2.5rem;
}

@media (min-width: 768px) {
  .form-control {
    width: calc(calc(100% - 3rem) / 3);
  }

  /* Force a line break after the 2nd element */
  .form-control--wrap {
    margin-right: calc(calc(100% - 3rem) / 3);
  }

  .form-actions {
    flex-direction: row;
  }
}
