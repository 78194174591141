@keyframes spinnerBorder {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  vertical-align: -0.125em;
  border-style: solid;
  border-color: currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: spinnerBorder;
}
