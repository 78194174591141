.peppol-code {
  background: var(--neutral-100);
  border-radius: 0.25rem;
  color: var(--content-primary);
  display: flex;
  justify-content: space-between;
  font-size: 0.9375rem;
  margin-bottom: 1.5rem;
  padding: 0.5rem 0.75rem;
}

.peppol-code__info {
  cursor: pointer;
  position: relative;
}

.peppol-code__info > svg {
  opacity: 0.85;
}

.peppol-code__tooltip {
  background: rgba(0 0 0 /0.8);
  border-radius: 0.25rem;
  color: var(--neutral-100);
  font-size: 0.8rem;
  position: absolute;
  right: calc(-0.75rem + 6px);
  top: 32px;
  width: 260px;
  padding: 0.5rem 0.75rem;
  text-align: center;
}

.peppol-code__tooltip::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid rgba(0 0 0 / 0.8);
  position: absolute;
  top: -8px;
  right: 8px;
}
