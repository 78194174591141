:root {
  --layout-max-width: 1400px;

  --white: #fff;
  --neutral-100: #f7faff;
  --neutral-200: #e8eaee;
  --neutral-900: #222;
  --black: #1e2022;
  --primary: #e5720d;
  --secondary: #21325b;

  --action-primary: #e5720d;
  --action-primary-2: #c3610b;
  --action-primary-3: #b75b0a;

  --success: #00c9a7;
  --error: #ed4c78;

  --content-primary: var(--black);
  --content-primary-inverse: var(--white);
  --content-secondary: #677788;

  --bg-primary: var(--white);
  --bg-primary-inverse: var(--primary);
  --bg-secondary: var(--neutral-100);
  --bg-secondary-inverse: var(--secondary);
  --bg-tertiary: #fdf1e6;
  --bg-action-primary: var(--action-primary);
  --bg-action-primary-hover: var(--action-primary-2);
  --bg-action-primary-pressed: var(--action-primary-3);

  --border-accent-primary: var(--primary);
}

body {
  background: var(--bg-secondary);
  overflow-x: hidden;
}

a {
  color: var(--primary);
}
