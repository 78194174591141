@keyframes slideIn {
  from {
    right: -29rem;
  }

  to {
    right: 0;
  }
}

.wrapper {
  position: fixed;
  top: 0;
  padding: 0.75rem;
  width: 100%;
  z-index: 100;
  pointer-events: none;
}

.alert {
  animation-duration: 200ms;
  animation-name: slideIn;
  border-radius: 0.5rem;
  max-width: 28rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  padding: 0.75rem;
  position: relative;
}

.alert--info {
  background: var(--bg-secondary-inverse);
  color: var(--content-primary-inverse);
}

.alert--error {
  background: var(--error);
  color: var(--content-primary-inverse);
}

.alert--success {
  background: var(--success);
  color: var(--content-primary-inverse);
}
