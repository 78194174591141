.wrapper {
  position: relative;
}

.dropdown-trigger {
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;
}

.dropdown {
  position: absolute;
  right: 0;
  background: var(--bg-primary);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  padding: 1rem;
}

.cta {
  background: transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 0;
  padding: 0;
}

.cta--disabled {
  pointer-events: none;
  opacity: 0.5;
}
