.accordion {
  border-top: 1px solid var(--neutral-200);
  border-bottom: 1px solid var(--neutral-200);
  padding: 0 1rem;
}

.accordion-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--bg-primary);
  border: 0;
  color: var(--content-primary);
  font-size: 1.125rem;
  font-weight: 600;
  padding: 1rem 0;
  width: 100%;
  transition: color 0.2s ease-in-out;
}

.accordion-trigger:hover {
  color: var(--action-primary);
}

.accortion-trigger-icon {
  color: var(--content-primary);
  transition: transform 500ms;
  transform: rotate(0);
}

.accortion-trigger-icon--rotate {
  transform: rotate(180deg);
}

.accordion-content {
  transition: max-height 500ms;
  overflow: hidden;
}
