.button {
  border: 0;
  border-radius: 0.3125rem;
  transition: background 0.2s ease-in-out, box-shadow 0.2s ease-in-out, color 0.2s ease-in-out;
}

.button--disabled {
  opacity: 0.65;
}

.button--sm {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.button--md {
  font-size: 1rem;
  padding: 0.612rem 1rem;
}

.button--lg {
  font-size: 1rem;
  padding: 0.75rem 1rem;
}

.button--primary {
  background: var(--bg-action-primary);
  color: var(--content-primary-inverse);
}

.button--primary:hover:not([disabled]) {
  background: var(--bg-action-primary-hover);
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1);
}

.button--primary:active {
  background: var(--bg-action-primary-pressed);
  box-shadow: none;
}

.button--primary-inverse {
  background: var(--bg-primary);
  color: var(--content-secondary);
}

.button--primary-inverse:hover:not([disabled]) {
  background: var(--bg-tertiary);
  box-shadow: none;
  color: var(--action-primary);
}

.button--primary-inverse:active {
  background: var(--bg-primary) !important;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.1) !important;
}
