.cta {
  appearance: none;
  background: transparent;
  border: 0;
  color: var(--error);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.hidden-trigger {
  display: none;
}
