.table-responsive {
  overflow-x: auto;
}

.table {
  color: var(--content-primary);
  width: 100%;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

.thead {
  background: var(--neutral-100);
  border-top: 1px solid var(--neutral-200);
  border-bottom: 1px solid var(--neutral-200);
}

.table th {
  font-weight: 600;
}

.table th,
.table td {
  padding: 0.75rem;
}

.table th:first-of-type,
.table td:first-of-type {
  padding-left: 1rem;
}

.table th:last-of-type,
.table td:last-of-type {
  padding-right: 1rem;
}

.table-nav {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 1rem;
  gap: 1rem;
}

@media (min-width: 768px) {
  .table-nav {
    flex-direction: row;
    align-items: center;
  }
}

.pagination {
  margin-left: auto;
}

.pagination--hidden {
  visibility: hidden;
}

.details {
  animation-duration: 500ms;
  animation-name: slideIn;
  height: 100vh;
  width: 60%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.content {
  width: 100%;
  height: 100%;
}
